import { state } from ":mods";
import { LOCALSTORAGE_KEYS } from "./const";
import { ACourseStage, AStageModule } from "./models";
import { getSubModuleRoute } from "./methods";
export const $selected_stage = state.createGlobal<string | number>(getSelectedStage());
export const $course_data = state.createGlobal<ACourseStage<true>>(undefined);
export const $selected_module = state.createGlobal<AStageModule<true>>(undefined);

export function getSelectedStage() {
  const stageSelected = localStorage.getItem(LOCALSTORAGE_KEYS.student_selected_stage);
  if (!stageSelected || stageSelected === "undefined") {
    return undefined;
  }
  return stageSelected;
}

export function setSelectedStage(id: string | number) {
  localStorage.setItem(LOCALSTORAGE_KEYS.student_selected_stage, id as string);
  $selected_stage.set(id);
}
export function removeStageSelected() {
  localStorage.removeItem(LOCALSTORAGE_KEYS.student_selected_stage);
  $selected_stage.set(undefined);
}
export function setCourseData(data: ACourseStage<true>) {
  $course_data.set(data);
}
export function setSelectedModule(data: AStageModule<true>) {
  $selected_module.set(data);
}

export const triggers = {
  removeStageSelected,
};

export const hooks = {
  get useSelectedStage() {
    return $selected_stage.derive((v) => v);
  },
};

export const actions = {
  getSubModuleRoute,
  getStageSelected() {
    return $selected_stage.unwrap;
  },
  getCourseData() {
    return $course_data.unwrap;
  },
};
